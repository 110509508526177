//Polyfill
import "core-js";
import jQuery from "jquery";
const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)

//Can't resolve 'regenerator-runtime/runtime'が表示されたとき(async awaitを使う場合など)
//import  regeneratorRuntime  from  "regenerator-runtime" ;

$(function(){
    $(".header-btn").on("click", function(){
        $(".header-nav").toggleClass("open");
        $(".header-btn").toggleClass("open");
    });
});